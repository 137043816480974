import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-message',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class MessageComponent {
  @Input() messageType: string = 'error';
  @Input() titleText: string = 'error';
  @Input() description: string = 'error';
}