import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccountActivationComponent } from './account-activation/account-activation.component';
import { LoginAuthGuard } from '../services/login.gauard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
 
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'activate',
    component: AccountActivationComponent,
    canActivate: [LoginAuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
