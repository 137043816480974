import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptcha2Component } from 'ngx-captcha';

import { environment } from 'src/environments/environment';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { Utils } from 'src/app/core/service/utils.service';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY } from 'src/app/resources/app.constant';
import { _REGEX_FOR_EMAIL } from 'src/app/resources/app.regex';
import { CustomHttpError } from 'src/app/types/interfaces';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordLabel:string;
  @ViewChild(ReCaptcha2Component, {static:true}) recaptcha2 :ReCaptcha2Component;
  // captcha
  public readonly siteKey:string = CAPTCH_SITE_KEY;
  public readonly secretKey:string = CAPTCH_SECREAT_KEY;
  public readonly theme: 'light' | 'dark' = 'light';
  public readonly size: 'compact' | 'normal' = 'normal';
  public defaultLanguage:string = 'es';
  public readonly captchaType: 'image' | 'audio';

  resetHttpStatus: HttpRequestState;
  forgotPasswordForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    recaptcha: ['', Validators.required]
  });
  httpResetPasswordError: Partial<CustomHttpError>;
  captchaMsg: string = '';
  busy: boolean = false;

  // recaptcha method
  handleReset() { }
  handleExpire() { }
  handleLoad() { }
  handleSuccess(_) { this.captchaMsg = ''; }

  constructor(
    @Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string,
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private utils: Utils,
    private router:Router,
    private translateService:TranslateService,
  ) { }

  ngOnInit():void{
    this.documentService.body.classList.add('blueBg');
    this.resetPasswordLabel = this.translateService.instant('reset_password');
    this.defaultLanguage= this.translateService.getDefaultLang();
  }

  ngOnDestroy():void {
    this.documentService.body.classList.remove('blueBg');
  }

  submit():void {
    if (this.forgotPasswordForm.invalid) {
      this.utils.markInvalidFields(this.forgotPasswordForm);
      const recaptcha = this.forgotPasswordForm.value.recaptcha;

      if (recaptcha === null || recaptcha === '' || recaptcha === undefined) {
        this.captchaMsg = this.translateService.instant('select_captch');
      } else {
        this.captchaMsg = '';
      }
      
    } else {
      this.resetHttpStatus = 'in_progress';
      this.busy = true;
      const formValues = this.forgotPasswordForm.getRawValue();
      this.http.post(environment.backend + 'account/resetPassword', formValues)
      .subscribe( (_: any) => {
          this.resetHttpStatus = 'completed';
          this.forgotPasswordForm.reset();
          this.busy = false;
          this.resetPasswordLabel = this.translateService.instant('reset_password');
        }, (error: any) => {
          this.busy = false;
          this.recaptcha2.reloadCaptcha();
          if (error.status === 400) {
            if(error.error.errorKey === 'CAPTCHA_ERROR'){
              this.forgotPasswordForm.controls.recaptcha.setValue(null);
            }else if(error.error.errorKey === 'INVALID_EMAIL') {             
              this.httpResetPasswordError = {
                message: this.translateService.instant('invalid_email'),
                status: 400
              };
            }else{
              this.httpResetPasswordError = {
                message: 'Bad request',
                status: 400
              };
            }

          }else if (error.status === 404){
            this.httpResetPasswordError = {
              message: this.translateService.instant('login_invalid_credential_warning_header'),
              status: 404
            };

          } else {
            this.httpResetPasswordError = {
              message: this.translateService.instant('cant_update_password'),
              status: error.statusCode
            };
          }
          this.resetHttpStatus = 'error';
          this.resetPasswordLabel = this.translateService.instant('reset_password');
        });
    }
  }

  navigateToLogin():void{
    this.router.navigate(['/login'],{queryParamsHandling:"preserve"});
  }

  highLigth(controlName):string[] {
    return this.forgotPasswordForm.get(controlName).touched && this.forgotPasswordForm.get(controlName).invalid ? ['requiredFieldText'] : [''];
  }
}
