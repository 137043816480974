import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  activationKey: string;
  accountActivation: HttpRequestState;
  email: string;
  httpResendActivationLinkStatus: HttpRequestState = 'not_initiated';
  errorMsg = '';

  constructor(
    @Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router,
    ){ }

  ngOnInit():void {
    this.accountActivation = 'in_progress';

    this.activatedRoute.queryParamMap.subscribe(q => {
      const key = q.get('key');
      const id = q.get('id');
      this.email = q.get('email');
      const approve = q.get('approve');
      const courseId = q.get('courseId');
      if(id){
        this.httpClient.get(`${environment.backend}activate?key=${key}&id=${id}&email=${this.email}&approve=${approve}&courseId=${courseId}`)
      .subscribe( (d: any) => {
          this.accountActivation = 'completed';
          console.log("completed11111", d)
        },
        (error:any) => {
          console.log(error, "errormsg");
          if (error.error.errorKey == 'already_activated') {
            this.accountActivation = 'info';
            // console.log("info22222", this.accountActivation)
          }
          else if(error.error.errorKey == 'ADMIN_REJECTED' ){
            this.accountActivation ='errorinfo';
            // console.log("info22222", this.accountActivation)
          }
          else if(error.error.errorKey == 'ADMIN_ALREADY_REJECTED' ){
            this.accountActivation ='errorrejectinfo';
            // console.log("info22222", this.accountActivation)
          }
          else if(error.error.errorKey == 'Activation_Expired' ){
            this.accountActivation ='errorrreject';
            // console.log("info22222", this.accountActivation)
          }
          else if(error.error.errorKey == 'previously_already_activated' ){
            this.accountActivation ='errorpreviouslyactive';
            console.log("info22222", this.accountActivation)
          }
          else if(error.error.errorKey == 'USER_ACTIVATION_FAILED' ){
            this.accountActivation ='UserActivationFailed';
            console.log("info22222", this.accountActivation)
          }
          
          else {
            this.accountActivation = 'error';
            // console.log("error", this.accountActivation)
            console.log("error3333", this.accountActivation)
          }

          this.errorMsg = error.error.message;
        }
      );

      }
      
      else{
        this.httpClient.get(`${environment.backend}activateWithoutID?key=${key}&email=${this.email}&approve=${approve}&courseId=${courseId}`)
        .subscribe( (d: any) => {
            this.accountActivation = 'completed';
            console.log("completed11111", d)
          },
          (error:any) => {
            console.log(error, "errormsg");
            if (error.error.errorKey == 'already_activated') {
              this.accountActivation = 'info';
              // console.log("info22222", this.accountActivation)
            }
            else if(error.error.errorKey == 'ADMIN_REJECTED' ){
              this.accountActivation ='errorinfo';
              // console.log("info22222", this.accountActivation)
            }
            else if(error.error.errorKey == 'ADMIN_ALREADY_REJECTED' ){
              this.accountActivation ='errorrejectinfo';
              // console.log("info22222", this.accountActivation)
            }
            else if(error.error.errorKey == 'Activation_Expired' ){
              this.accountActivation ='errorrreject';
              // console.log("info22222", this.accountActivation)
            }
            else if(error.error.errorKey == 'previously_already_activated' ){
              this.accountActivation ='errorpreviouslyactive';
              console.log("info22222", this.accountActivation)
            }
            else if(error.error.errorKey == 'USER_ACTIVATION_FAILED' ){
              this.accountActivation ='UserActivationFailed';
              console.log("info22222", this.accountActivation)
            }
            
            else {
              this.accountActivation = 'error';
              // console.log("error", this.accountActivation)
              console.log("error3333", this.accountActivation)
            }
  
            this.errorMsg = error.error.message;
          }
        );
  
      }
      
    });
  }


  backToLogin():void {
    this.router.navigate(['login']);
  }

}
