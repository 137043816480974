import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';

import { InputDataMaskDirective,FormControlTextValueTrimDirective,ConvertToNumberDirective } from './input-data-mask.directive';
import { MessageComponent } from './alert/alert.component';
import { LogoutConfirmationDialogComponent } from './logout-confirmation-dialog/logout-confirmation-dialog.component';
import { AuthAlertDialogComponent } from './auth.error.alert/auth-alertcomponent';
import { UserService } from '../services/userService';
import { FooterFixedPostionComponent } from './footer-fixed-position/footer.component';
import { LowercaseCountryWordsPipe } from './lowercase-country-words.pipe';
import { SessionWarningAlertComponent } from './session-warning-model-dialog/session-warning-alert.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AlertComponent } from './alert.dialog/alert.dialog';
import { ThirdPartiesComponent } from './third-parties/third-parties.component';
import { HttpLoaderFactory } from '../core/service/MultiTranslationFactory';

const components = [
  HeaderComponent,
  FooterComponent,
  FooterFixedPostionComponent,
  AlertComponent,
  MessageComponent,
  LogoutConfirmationDialogComponent,
  AuthAlertDialogComponent,
  SessionWarningAlertComponent,
  ThirdPartiesComponent
];



@NgModule({
  entryComponents: [
    ...components
  ],
  declarations: [
    ...components,
    FormControlTextValueTrimDirective,
    InputDataMaskDirective,
    ConvertToNumberDirective,
    LowercaseCountryWordsPipe,
    
  ],
  exports: [
    ...components,
    FormControlTextValueTrimDirective,
    InputDataMaskDirective,
    ConvertToNumberDirective,
    LowercaseCountryWordsPipe,
    TranslateModule

  ],
  imports: [
    CommonModule, 
    RouterModule,
    NgbDropdownModule, 
    MatMenuModule, 
    MatButtonModule,
    MatDialogModule, 
    CountdownModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    })
  ],
  providers: [CookieService, UserService,

    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory }]

})
export class ComponentModule { }
export function countdownConfigFactory() {
  return { format: `mm:ss` };
}