import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

import { _REGEX_FOR_EMAIL, _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY, DAKONET_SPAIN_EMAIL, policyLink } from 'src/app/resources/app.constant';
import { UserService } from 'src/app/services/userService';
import { CurrentUserInterface, LoginRequestPayload } from 'src/app/types/current-user.interface';
import { CustomHttpError } from 'src/app/types/interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild("passwordInputRef", { static: false }) passwordInputRef: ElementRef;
  @ViewChild(ReCaptcha2Component, { static: true }) recaptcha2: ReCaptcha2Component;
  public readonly mail_for_spain:string = DAKONET_SPAIN_EMAIL;
  public courseId:string = null;
  public loginForm: FormGroup;
  public loginProgress: boolean = false;
  public hide: boolean = true;
  public httpLoginStatus: HttpRequestState;
  public afterRegistrationMsg: string = '';
  public registrationMessageRef: any;
  public httpLoginError: CustomHttpError;
  public httpResendLinkError: CustomHttpError
  public httpResendActivationLinkStatus: HttpRequestState;
  public loginCount:number = 0;

  public readonly siteKey: string = CAPTCH_SITE_KEY;
  public readonly secretKey: string = CAPTCH_SECREAT_KEY;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'es';
  public captchType: 'image'|'audio' = 'image' ;
  public readonly policyLink :string = policyLink
  constructor(
    @Inject(DOCUMENT) private documentService,
    @Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
  ) {
    this.documentService.body.classList.add('blueBg');
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
      recaptcha: this.formBuilder.control('')
    });
  }

  handleReset() { }
  handleExpire() { }
  handleLoad() { }
  handleSuccess(_) { }

  ngOnInit(): void {
    this.httpLoginStatus = 'not_initiated';
    this.afterRegistrationMsg = sessionStorage.getItem('showRegistrationMessage');
    this.registrationMessageRef = setTimeout(() => { this.clearMessage(); }, 10000);

    this.activatedRoute.queryParamMap.subscribe(q => {
      const courseId = q.get('courseId');
      if (courseId) {
        sessionStorage.setItem('COURSEID', courseId.toString());
        this.courseId = courseId
        if (this.userService.user()) {
          this.router.navigate(['/user/course/', courseId]);
        }
      }
    })

  }

  ngOnDestroy(): void {
    clearTimeout(this.registrationMessageRef);
    this.clearMessage();
    this.documentService.body.classList.remove('blueBg');
  }

  clearMessage(): void {
    this.afterRegistrationMsg = '';
    sessionStorage.removeItem('showRegistrationMessage');
  }

  register(): void {
    this.router.navigate(['/register'], { queryParamsHandling: "merge" })
  }

  navigateToForgotPassword(): void {
    this.router.navigate(['/forgotpassword'], { queryParamsHandling: "merge" })
  }


  isCaptcha(): boolean {
    let isValid: boolean;
    const captcha = this.loginForm.controls.recaptcha.value;
    if (this.loginCount >= 2 && (captcha === '' || captcha === null || captcha === undefined)) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  getLoginRequestPayload(): LoginRequestPayload {
    const { userName, password, recaptcha } = this.loginForm.getRawValue();
    return { tokenId: userName, password, recaptcha };
  }

  authenticateUser(): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.duration = 3000;

    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      if (this.isCaptcha()) {
        this.loginProgress = true;
        this.httpLoginStatus = 'in_progress';
        const requestBody = this.getLoginRequestPayload();

        this.userService.authenticateUser(requestBody).subscribe(
          (value: CurrentUserInterface) => {
            localStorage.clear();
            sessionStorage.clear();
            this.httpLoginStatus = 'completed';
            if (this.courseId) {
              this.router.navigate(['/user/course/', this.courseId]);
            } else {
              this.router.navigate(['/user/lms-courses']);
            }
            sessionStorage.setItem('agilent-auth-token', value.id_token);
            sessionStorage.setItem('userObject', JSON.stringify(value));

            this.userService.setCountryCode(value.countryCode);
            this.loginProgress = false;

          }, (error: HttpErrorResponse) => {
            this.httpLoginStatus = 'error';

            this.loginProgress = false;
            this.loginCount = Number(error.error.count);

            if (error.status === 400) {
              if (error.error.errorKey === 'CAPTCHA_ERROR') {
                this.recaptcha2.reloadCaptcha();
                this.loginForm.controls.recaptcha.setValue(null);
                this.httpLoginError = {
                  type: 'error',
                  status: 400,
                  errorKey: error.error.errorKey,
                  message: error.error.message,
                  heading: 'Error'
                };

              } else {
                this.httpLoginError = {
                  type: 'error',
                  status: 400,
                  errorKey: error.error.errorKey,
                  heading: this.translateService.instant('error'),
                  message: this.translateService.instant('login_bad_credential_warning')
                };
              }


            } else if (error.status === 401) {
              if (error.error.errorKey === 'account_not_activated') {
                this.httpLoginError = {
                  type: 'warn',
                  errorKey: 'account_not_activated',
                  status: 401,
                  heading: this.translateService.instant('activation_pending'), //'Activation pending',
                  message: this.translateService.instant('registeration_pending_text') //`Your request is under review, you’ll be notified once approved.`
                };

              } else
                if (error.error.errorKey === 'invalid_credential') {
                  this.httpLoginError = {
                    type: 'error',
                    errorKey: 'invalid_credential',
                    status: 401,
                    heading: this.translateService.instant('login_invalid_credential_warning_header'),
                    message: this.translateService.instant('login_invalid_credential_warning')
                  };
                } else
                  if (error.error.errorKey === 'account_locked') {
                    this.httpLoginError = {
                      type: 'error',
                      errorKey: 'account_locked',
                      status: 401,
                      heading: this.translateService.instant('user_account_block_warning_heading'), //'Account Locked', // error.error.message || ,
                      message: this.translateService.instant('user_account_block_warning_msg') //'Your Account has been blocked as you entered wrong password three time, Please wait for one minute to unlock.'
                    };
                  } else {
                    this.httpLoginError = {
                      type: 'error',
                      errorKey: 'Bad Credentials',
                      status: null,
                      heading: this.translateService.instant('login_bad_credential_warning_header'), //'Authentication error', // error.error.message || ,
                      message: this.translateService.instant('login_bad_credentials')
                    };
                  }

            } else {
              this.httpLoginError = {
                status: Number(error.status),
                type: 'error',
                errorKey: null,
                heading: this.translateService.instant('server_error'),
                message: this.translateService.instant('unknown_error')
              };
            }

          });
      } else {
        this.matSnackBar.open(this.translateService.instant('select_captch'), 'OK', { duration: 3000 });
      }
    }
  }

  togglePasswordMode(): void {
    this.hide = !this.hide;
    const passwordField = this.passwordInputRef.nativeElement;
    passwordField.focus();
  }

}
