import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxCaptchaModule } from 'ngx-captcha';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { MatSnackBarModule, MatMenuModule } from '@angular/material';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { ComponentModule } from '../component/components.module';
import { AccountActivationComponent } from './account-activation/account-activation.component';
import { HttpLoaderFactory } from '../core/service/MultiTranslationFactory';


@NgModule({
  declarations: [LoginComponent,
    RegisterComponent,

    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccountActivationComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatMenuModule,
    NgbDropdownModule,
    ComponentModule,
    NgxCaptchaModule,
    FormsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers:[TranslateService]
})
export class AuthenticationModule { }
