import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SessionWarningAlertComponent } from '../component/session-warning-model-dialog/session-warning-alert.component';
import { ApiService } from './api-service.service';
import { UserService } from './userService';

@Injectable({
    providedIn: 'root'
})
export class SessionTrackingService {
    private watchIntervalRef: any = null;
    private logOutTimerRef: any = null;
    private isUserIdeal = false;
    private  refreshServerSessionInverval:any  = null;
    private  pingToServer : "open" | "blocked" = "open";

    constructor(private apiService:ApiService, private router:Router, private dialog: MatDialog, private userService:UserService){}


    setIdeal(isUserIdeal) {
        this.isUserIdeal = isUserIdeal;
           
      
        if( !isUserIdeal){
            clearTimeout(this.logOutTimerRef);
            this.logOutTimerRef = null;
            this.refreshServerSession();
            this.startSession();
        }
    }
  

    logOutTimer() {
        return setTimeout(() => { 
          
            this.stopSession(); 
        }, (1000 * 60)* 5 );
    }


    startSession() {
         
        this.isUserIdeal = true
        if (this.watchIntervalRef) { clearInterval(this.watchIntervalRef); }
        this.watchIntervalRef = this.watchInterval();
    }    


    watchInterval() {
        return setInterval(() => {
            if (this.isUserIdeal) {
                if(!this.logOutTimerRef){
                    this.logOutTimerRef = this.logOutTimer();
                }
            } else {
                this.isUserIdeal = true
                // if NOT ideal for 10sec then start timeout                       
                clearTimeout(this.logOutTimerRef);
                this.logOutTimerRef = null
                //TODO make profile call to update session login            
            }
        }, (1000 * 60)* 25 )
    }


    stopSession() {
        const userObject = JSON.parse(sessionStorage.getItem('userObject'));
        
        if(userObject) {
            
         clearInterval(this.watchIntervalRef);
         this.watchIntervalRef = null;

        this.dialog.open(SessionWarningAlertComponent, {
            width:'560px',
            height:'356px'
        }).afterClosed().subscribe((confirmed: boolean) => {
          if (!confirmed) {        
            clearTimeout(this.logOutTimerRef);          
            this.logOutTimerRef = null;
            this.isUserIdeal = true;

            this.userService.removeSessionDetails();
            this.router.navigateByUrl('/login').then( ()=> window.location.reload() )
            this.apiService.get(`logOut/${ userObject.userId}`).subscribe(_ => { });
            }else {
                this.apiService.get(`user/${ userObject.userId}`).subscribe(_ => { })
                clearTimeout(this.logOutTimerRef);
                this.logOutTimerRef = null;
                this.startSession();             
            }
        });
    }
 }

    showDialog(){
        //TODO alert user about session termination
    }

    stopTimers(){               
        clearInterval(this.watchIntervalRef);
        clearTimeout(this.logOutTimerRef);
        this.watchIntervalRef = null;
        this.logOutTimerRef = null;
        this.isUserIdeal = null;
    }


    refreshServerSession() {
            if(this.pingToServer === 'open'){
                this.pingToServer = "blocked";
               
                if(this.refreshServerSessionInverval){
                    clearTimeout(this.refreshServerSessionInverval);
                    this.refreshServerSessionInverval = null;
                }

                this.refreshServerSessionInverval = setTimeout( ()=> { 
                    this.pingToServer = 'open';  
               },2000*60*2);

               const userObject = JSON.parse(sessionStorage.getItem('userObject'));
               this.apiService.get(`user/${userObject.userId}`).subscribe(_ => { });
            }
    } 
}