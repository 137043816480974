import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/service/utils.service';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { ApiService } from 'src/app/services/api-service.service';
import { _REGEX_FOR_MOBILE, _REGEX_FOR_ONLY_CHAR } from 'src/app/resources/app.regex';
import { TranslateService } from '@ngx-translate/core';
import { ProfileResponseInterface } from 'src/app/types/interfaces';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  countries$ = this.userService.loadCountries();

  httpProfileGetState: HttpRequestState = 'not_initiated';
  httpProfilePutState: HttpRequestState = 'not_initiated';
  user: any = null;

  profileForm: FormGroup;
  httpPutError: { status: any; title: any; };

  constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private apiService: ApiService,
        private router: Router,
        private utils: Utils,
        private translateService:TranslateService
    ) { }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      lastName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      email: null,
      countryCode: null,
      institution:this.formBuilder.control(null, {
        validators: [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      dniNumber:null,
      mobileNumber:this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(15)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      city:this.formBuilder.control(null, {
        validators: [Validators.maxLength(500)],
        asyncValidators: null,
        updateOn: 'blur'
      })
    });
    this.httpProfileGetState = 'in_progress';
    const authUser = this.userService.user();
    
    this.apiService.get(`user/${authUser.userId}`).subscribe((value: any) => {
      this.httpProfileGetState = 'completed';
      this.user = value.data;

      this.profileForm.setValue({
        firstName:  this.user.firstName,
        lastName: this.user.lastName,
        email:  this.user.email,
        countryCode:  this.user.countryCode,
        institution:  this.user.institution,
        dniNumber:  this.user.dniNumber,
        mobileNumber:  this.user.mobileNumber,
        city:this.user.city
      });



      this.userService.setCountryCode(this.user.countryCode);
    }, (_) => {
      this.httpProfileGetState = 'error';
    });
  }

  highLigth(controlName) {
    return this.profileForm.get(controlName).touched && this.profileForm.get(controlName).invalid ? ['requiredFieldText'] : '';
  }

  saveUserDetails() {
    this.httpPutError = null;

    if (this.profileForm.invalid) {
      this.utils.markInvalidFields(this.profileForm);
    } else {
      this.httpProfilePutState = 'in_progress';
      const updatedProfile = this.profileForm.getRawValue();
      const authoritiesToStringArray = [];

      this.user.authorities.forEach(element => {
        authoritiesToStringArray.push(element.name);
      });

      if (authoritiesToStringArray[0] === null || authoritiesToStringArray.length === 0) {
        authoritiesToStringArray[0] = 'ROLE_USER';
      }
      this.user.authorities = authoritiesToStringArray;
      const requestPayload = Object.assign(this.user, updatedProfile);

      this.apiService.putMethod('user', requestPayload)
          .subscribe((response: ProfileResponseInterface) => {
                this.user = response.data;
                this.userService.setCountryCode(this.user.countryCode);
                this.httpProfilePutState = 'completed';
              // sessionStorage.removeItem('userObject')
              // sessionStorage.setItem('userObject',JSON.stringify(this.user))
              }, (error: any) => {

                this.httpProfilePutState = 'error';
                if (error.error.status === 400) {
                  this.httpPutError = {
                    status: error.error.status,
                    title: error.error.title,
                  };
                } else {
                  this.httpPutError = error;
                }

              });
    }
  }

  validateName() {
    const firstName = this.profileForm.get('firstName');
    const lastName = this.profileForm.get('lastName');

    if (firstName.invalid) {
      if ((firstName.touched || firstName.dirty) && firstName.hasError('required')) {
        return { error: 'required', message: this.translateService.instant('firstName_required')  }; //'First name is required'
      }
      if (firstName.hasError('pattern')) {
        return { error: 'pattern', message:  this.translateService.instant('firstName_only_character')  }; // 'First name should only characters'
      }
      if (firstName.hasError('maxlength')) {
        return { error: 'pattern', message: this.translateService.instant('maxLength_exceed')  }; // 'Maximum character length execeeded'
      }
    }

    if (lastName.invalid) {
      if ((lastName.touched || lastName.dirty) && lastName.hasError('required')) {
        return { error: 'required', message:   this.translateService.instant('lastName_required') }; //'Last name is required'
      }
      if (lastName.hasError('pattern')) {
        return { error: 'pattern', message: this.translateService.instant('lastName_required_character')   }; //'Last name should only characters'
      }
      if (lastName.hasError('maxlength')) {
        return { error: 'pattern', message: this.translateService.instant('maxLength_exceed') }; // 'Maximum character length execeeded'
      }
    }

  }

  cancel() {
    this.router.navigate(['/user/lms-courses']);
  }

}



