import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-footer-fixed-position',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterFixedPostionComponent {
  constructor(@Inject('AGILENT_TRUSTED_ANSWER_LOGO') public agilent_trusted_answer_logo:string) { }
}
