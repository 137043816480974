import { Component, OnInit, Inject,  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/services/api-service.service';
import {  ActivatedRoute, ParamMap } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare var OpenSeadragon: any;
@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
  viewer: any;
  isFullScreen = false;
  imageGroup: StainInterface[] = [];
  selectedStainType: any;
  selectedZoom: number=1;

  constructor(@Inject(DOCUMENT) private document: Document,
              private httpClient: HttpClient,
              private httpService: ApiService,
              private translateService:TranslateService,
              private activateRoute: ActivatedRoute) {
  }


  validateUser(sesssionToken, groupId) {
    this.httpService.tokenVerification(sesssionToken).subscribe((_: any) => {
      this.loadImageDetails(sesssionToken, groupId);

    }, (tokenValidateError) => {
      alert('Error occured Authentication failed\n'+tokenValidateError);
    });
  }


  loadImageDetails(sesssionToken, groupId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        Authorization: 'Bearer ' + sesssionToken
      })
    };

    this.httpClient.get(`${environment.backend}image/groupId/${groupId}`, httpOptions).subscribe((response: any) => {
      this.imageGroup = response.data;

      // this.viewer = this.getOsdViewer(this.imageGroup[0].filePath, this.imageGroup[0].dziPath);
      this.stainChange('HE');

    }, (error: any) => {
      alert('Error occured while loading image details\n'+error);
    });
  }


  stainingButtonsContainerStyle() {
    return {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '40px',
      'z-index': '5',
      'background-color': '#0085D5'
    };
  }


  getOsdViewer(filePath, dziPath) {
    const viewer = OpenSeadragon({
      id: 'agilent-stanning-viewer',
      prefixUrl: filePath,
      tileSources: dziPath,
      toolbar: 'stainingButtonsContainer',
      showNavigator: true,
      navigatorPosition: 'BOTTOM_RIGHT',
      showZoomControl: false,
      showHomeControl: false,
      showFullPageControl: false,
      autoHideControls: false,
    });


    viewer.addHandler('open', () => {

      const displayregion: any = this.document.querySelector('.displayregion');
      displayregion.style.borderColor = 'yellow';


    });

    return viewer;
  }


  stainChange(stainType) {
    this.selectedStainType = stainType;
    const imageObject = this.imageGroup.find((stain: StainInterface) => stain.stainType === stainType);
    if (this.viewer) {
      this.viewer.destroy();
    }

    if (imageObject) {
      this.viewer = this.getOsdViewer(imageObject.filePath, imageObject.dziPath);
    } else {
      alert('Stain Not Available');
    }
  }


  ngOnInit() {
    this.translateService.setDefaultLang('es');
     this.activateRoute.paramMap.subscribe((params: ParamMap) => {
      const groupId = params.get('g');
      if (!groupId) {
        alert('valid group id expected');
      }

      const user = JSON.parse(sessionStorage.getItem('userObject'));
      this.loadImageDetails(user.id_token, groupId);
    });



  }


  buttonStyle(marginRight = '1px') {
    const styleS: any = {
      display: 'inline-block',
      width: '50px',
      height: '30px',
      'background-color': '#00A9E0',
      'padding-top': '10px',
      'margin-right': marginRight,
      'text-align': 'center',
      color: '#FFFFFF',
      'font-size': '14px',
      'font-family': 'Roboto-medium, Arial, Helvetica, sans-serifRoboto-Medium',
      cursor: 'pointer',
      border: '0px',
    };
    return styleS;

  }


  buttonStyleWithPaddding(marginRight = '1px') {
    const styleS: any = {
      display: 'inline-block',
      width: '50px',
      height: '40px',
      'background-color': '#00A9E0',
      'padding-top': '10px',
      'margin-right': marginRight,
      'text-align': 'center',
      color: '#FFFFFF',
      'font-size': '14px',
      'font-family': 'Roboto-medium, Arial, Helvetica, sans-serifRoboto-Medium',
      cursor: 'pointer',
      border: '0px',
      'margin-top': '-10px',

    };
    return styleS;
  }


  setZoomTo(num: number) {
    this.selectedZoom = num;
    if (num === 1) {
      this.viewer.viewport.goHome(false);
    } else {
      this.viewer.viewport.zoomTo(Number(num));
    }
  }


  goHome() {
    this.viewer.viewport.goHome(true);
    this.setZoomTo(1)
  }


  zoomOut() {
    const gz = this.viewer.viewport.getZoom();
    if ((gz - 1) > 0) {
      this.viewer.viewport.zoomTo(Number(gz) - 1);
    }
  }


  zoomIn() {
    const gz = this.viewer.viewport.getZoom();
    this.viewer.viewport.zoomTo(Number(gz) + 1);
  }


  setFullScreen() {
    const displayregion: any = this.document.querySelector('.displayregion');
    displayregion.style.borderColor = 'yellow';

    this.isFullScreen = (this.isFullScreen) ? false : true;

    if (this.isFullScreen) {
      this.viewer.setFullScreen(true);

      const stainingButtonsContainer = this.document.getElementById('stainingButtonsContainer');
      stainingButtonsContainer.style.position = 'absolute';

    } else {
      this.viewer.setFullScreen(false);
    }
  }

  containerSize() {
    return { height: '400px', width: '750px' };
  }

}
