import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { RouterModule } from '@angular/router';
import { ComponentModule } from './component/components.module';

import { SimpleModalModule } from 'ngx-simple-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewerComponent } from './stanning-viewer/viewer/viewer.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileModule } from './profile/profile.module';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { AuthGuard } from './core/gaurd/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthErrorInterceptor } from './services/auth.error.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    ViewerComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ComponentModule,

    SimpleModalModule.forRoot({ container: 'modal-container' }),
    BrowserAnimationsModule,
    NgbDropdownModule,

    ProfileModule,
    AuthenticationModule,
    AppRoutingModule
  ],

  providers: [AuthGuard,
  { 
    provide: 'AGILENT_DAKONET_LOGO', 
    useValue: 'assets/images/dako_wordmark_ko_rgb.svg'
  },
  { 
    provide: 'AGILENT_TRUSTED_ANSWER_LOGO', 
    useValue: 'assets/images/agilent_logo_tag_h_ko_rgb.svg'
  },
 
  { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

