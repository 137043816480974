import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay, retry } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CurrentUserInterface, LoginRequestPayload } from '../types/current-user.interface';
import { TranslateService } from '@ngx-translate/core';
import { CountryInterface, CountryListResponseInterface } from '../types/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly countryCode$ = new BehaviorSubject(null);

    constructor(
        private httpClient: HttpClient, 
        private cookieService: CookieService,
        private translateService: TranslateService) { }

    user(): CurrentUserInterface {
        const currentUser: CurrentUserInterface = JSON.parse(sessionStorage.getItem('userObject'));
        return currentUser;
    }

    getAuthToken() {
        const currentUser: CurrentUserInterface = JSON.parse(sessionStorage.getItem('userObject'));
        return currentUser !== null ? currentUser.id_token : null;
    }

    authenticateUser(requestJSON:LoginRequestPayload):Observable<CurrentUserInterface> {
        const url = `${environment.backend}authenticate`;
        return this.httpClient.post(url, requestJSON)
            .pipe(
                map((response: any) => {
                    this.setCountryCode(response.countryCode);
                    return response;
                }),
            );
    }

    registerUser(requestJSON):Observable<any> {
        const url = `${environment.backend}register`;
        return this.httpClient.post(url, requestJSON);
    }

    assignBatch(userId):Observable<any> {
        return this.httpClient.post(`${environment.backend}user/${userId}/batch`, { batchId: [3001] });
    }

    contryList$:Observable<CountryInterface[]> = this.httpClient.get(`${environment.backend}country`)
    .pipe(
        map((res: CountryListResponseInterface) =>{ 
            const sorter = (sortBy) => (a, b) => a[sortBy].toLowerCase().trim() > b[sortBy].toLowerCase().trim() ? 1 : -1;
            let sortedCountryList : CountryInterface[] = res.data.sort(sorter('countryName'))    
            return  sortedCountryList;
        }),
        shareReplay(1), // buffer size  = 1
        retry(3),
        catchError( this.countryMasterError)
    );

    loadCountries():Observable<CountryInterface[]> {
        return this.contryList$;
    }

    countryMasterError(error):Observable<any> {
        const message = this.translateService.instant('countries.get.failure')
        alert(message);
        return throwError(error);
    }

    resendActivationLink(email):Observable<any> {
        const url = `${environment.backend}activate/link?email=${email}`;
        return this.httpClient.get(url);
    }

    isUserLoggedIn(): boolean {
        if (this.user()) {
            return true;
        }
        return false;
    }

    getFullName(): string {
        const { firstName, lastName } = this.user();
        const fullName = firstName ? firstName : '' + ' ' + lastName ? lastName : '';
        return fullName.trim();
    }

    getCountryCode(): string {
        const countryCode = JSON.parse(sessionStorage.getItem('countryCode'));
        this.setCountryCode(countryCode);
        return countryCode ? countryCode : '';
    }

    setCountryCode(countryCode: string | number): void {
        sessionStorage.setItem('countryCode', JSON.stringify(countryCode));
        this.countryCode$.next(countryCode);
    }

    _getCountryCode(): Observable<any> {
        return this.countryCode$ as Observable<any>;
    }

    removeSessionDetails(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
    }
}


