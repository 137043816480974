import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/userService';
import { ApiService } from 'src/app/services/api-service.service';

import { ScormWindowService } from 'src/app/services/scorm.window.service';
import { LogoutConfirmationDialogComponent } from '../logout-confirmation-dialog/logout-confirmation-dialog.component';
import { SessionTrackingService } from 'src/app/services/session-tracking-service';
import { CountryInterface } from 'src/app/types/interfaces';
import { CurrentUserInterface } from 'src/app/types/current-user.interface';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  providers: [NgbDropdownConfig],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  countryMaster:CountryInterface[] = [];
  userName: string = '';
  countryName: string = ''; 
  userObject: CurrentUserInterface;

  constructor(@Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string,
              private userService: UserService,
              private apiService: ApiService,
              private dialog: MatDialog,
              private scormWindowService: ScormWindowService,
              private sessionTrackingService : SessionTrackingService,
              private router: Router
              ) { }

  ngOnDestroy(): void {}
  ngOnInit(): void  {
    this.userObject = this.userService.user();
    if (this.userObject !== null && this.userObject !== undefined) {
      this.getUser(this.userObject);
    }

    this.router.events.subscribe(event =>{
        if(event instanceof NavigationEnd){
            this.sessionTrackingService.setIdeal(false);
        }
    })
  }


  getUser(userObject): void  {
    this.apiService.get(`user/${userObject.userId}`).subscribe(response => {
      if (response.data.countryCode !== null) { this.getCountryMaster(response.data.countryCode); }
      this.userName = response.data.firstName + ' ' + response.data.lastName;
      sessionStorage.setItem('countryCode', JSON.stringify(response.data.countryCode));
    }, _ => { });
  }

  async logout(): Promise<void>  {

    /**
     * 1. user click on logout button
     * 2. if course if launched,
     *    i. tracking api gets called
     *    ii.window closes
     *    iii. Data get clean
     * 3. User logout
     */

     this.dialog.open(LogoutConfirmationDialogComponent, { })
     .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
      const isWindowOpen = this.scormWindowService.isCourseWindowOpen();

      if (isWindowOpen) {
        const logoutFlag = true;
        this.scormWindowService.close(logoutFlag);
      } else {

        this.sessionTrackingService.stopTimers();
        this.apiService.get(`logOut/${ this.userObject.userId}`).subscribe( _ => {
          this.userService.removeSessionDetails();
          this.router.navigateByUrl('/login');
        });

      }
    }

    });
  }
  getCountryMaster(countryCode:number):void {
    let country:CountryInterface[] = [];
    this.userService.loadCountries().subscribe(result => {
      country = result.filter(item => Number(item.countryCode) === Number(countryCode));
      this.countryName = country[0].countryName;
    });
  }

  changePassword() {
    this.router.navigate(['/changePassword']);
  }

  gotoProfile() {
    this.router.navigate(['/profile']);
  }
}
