import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ViewportScroller } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(private viewportScroller: ViewportScroller) { }

  markInvalidFields(formGroup: FormGroup):void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markInvalidFields(control);
      }
    });
  }

  scrollToTop():void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  sortBy<Type>(array:Array<Type>, sortByKey:string):Array<Type>{
    const sorter = (sortBy) => (a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
    return  array.sort(sorter(sortByKey))
  }
  
}
