import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowercaseCountryWords'
})
export class LowercaseCountryWordsPipe implements PipeTransform {
  constructor() { }

  transform(value: string): string {

    if (value.toUpperCase() === "ANDORRA") {
      return value;
    }

    value = value.replace('And', 'and');
    value = value.replace('Of', 'of');
    return value;
  }

}
