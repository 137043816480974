import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from '../core/gaurd/auth.guard';


const routes: Routes = [{
  path: 'profile',
  component: ProfileComponent,
  canActivate: [AuthGuard]
}, {
  path: 'changePassword',
  component: ChangePasswordComponent,
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
