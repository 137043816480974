import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { Utils } from 'src/app/core/service/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import repeatPasswordValidation from '../custom-validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  hide: boolean = true;
  resetHttpStatus: HttpRequestState;
  passwordForm: FormGroup;
  activationKey: string | number;
  httpResetPasswordError: { status: number; message: string; };

  initializeResetPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      password: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      confirmPassword: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
    }, { validators: repeatPasswordValidation });
  }

  constructor(
    @Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string,
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar,
    private utils: Utils,
    private httpClient: HttpClient,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.documentService.body.classList.add('blueBg');
    this.resetHttpStatus = 'not_initiated';
    this.initializeResetPasswordForm();
    this.activatedRoute.queryParamMap.subscribe(q => {
      this.activationKey = q.get('key');
    });
  }

  ngOnDestroy(): void {
    // remove the class form body tag
    this.documentService.body.classList.remove('blueBg');
  }

  navigateToLogin(): void {
    this.router.navigate(['/login'], { queryParamsHandling: "merge" });
  }

  submit(): void {
    if (this.passwordForm.invalid) {
      this.utils.markInvalidFields(this.passwordForm);
    } else {
      this.resetHttpStatus = 'in_progress';
      const formValues = this.passwordForm.getRawValue();
      const requestBody = {
        key: this.activationKey,
        newPassword: formValues.confirmPassword
      };

      if (this.activationKey) {
        this.httpClient.post(environment.backend + 'account/resetPassword/finish', requestBody)
          .subscribe((_) => {
            this.resetHttpStatus = 'completed';
            const loginInterval = setTimeout(() => {
              this.initializeResetPasswordForm();
              this.router.navigate(['/login'])
                .then(() => {
                  clearTimeout(loginInterval);
                });
            }, 3000);
          }, (error: any) => {
            this.resetHttpStatus = 'error';
            this.initializeResetPasswordForm();
            if (error.status === 500 && error.error.title === 'No user was found for this reset key') {
              this.httpResetPasswordError = {
                status: 500,
                message: this.translateService.instant('reset_password_link_already_used')
                //'You have already changed password with this link. Link can not be reused'
              };
            } else if (error.status === 400 && error.error.errorKey === 'PASSWORD_EXIST') {
              this.httpResetPasswordError = {
                status: 400,
                message: this.translateService.instant('reset_password_exist')
                //'You can not use last three password as your new password'
              }
            } else {
              this.httpResetPasswordError = {
                status: error.status,
                message: this.translateService.instant('reset_password_error')
                //'Error occured while resetting password.'
              };
            }
          });
      } else {
        this.matSnackbar.open('Invalid URL');
      }
    }
  }

  highLigth(controlName): string[] {
    return this.passwordForm.get(controlName).touched && this.passwordForm.get(controlName).invalid ? ['requiredFieldText'] : [''];
  }

}