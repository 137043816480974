import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { ReCaptcha2Component } from 'ngx-captcha';

import { UserService } from 'src/app/services/userService';
import { Utils } from 'src/app/core/service/utils.service';
import { AlertComponent } from 'src/app/component/alert.dialog/alert.dialog';
import { _REGEX_FOR_EMAIL, _REGEX_FOR_MOBILE, _REGEX_FOR_ONLY_CHAR, _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { HttpErrorResponse } from '@angular/common/http';
import repeatPasswordValidation from 'src/app/authentication/custom-validations';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY, DAKONET_SPAIN_EMAIL } from 'src/app/resources/app.constant';
import { CustomHttpError } from 'src/app/types/interfaces';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  entryComponents: [AlertComponent],

})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild("passwordInputRef",{static:false}) passwordInputRef:ElementRef;
  @ViewChild(ReCaptcha2Component, { static: true }) recaptcha2: ReCaptcha2Component;

  public hide:boolean = true;
  public hideConfirm:boolean = true;
  public registrationProgress: HttpRequestState;
  public countries$ = this.userService.loadCountries();
  public registerForm: FormGroup;
  public httpRegistrationError: Partial<CustomHttpError>
  public courseId:string
  
  // captcha
  public readonly siteKey = CAPTCH_SITE_KEY;
  public readonly secretKey = CAPTCH_SECREAT_KEY;
  public readonly  mail_for_spain = DAKONET_SPAIN_EMAIL

  public readonly theme: 'light' | 'dark' = 'light';
  public readonly size: 'compact' | 'normal' = 'normal';
  public readonly capchaType: 'image' | 'audio';
  public lang = 'es';


  constructor(
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utils: Utils,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    @Inject('AGILENT_DAKONET_LOGO') public agilent_logo: string) { }
  
  ngOnInit():void {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.registerForm = this.createRegistrationForm();
    this.registrationProgress = 'not_initiated';
    this.documentService.body.classList.add('blueBg');
    this.lang = this.translateService.getDefaultLang();
  }

  handleSuccess(data):void {
    if (data !== null && data !== '' && data !== undefined) {
      this.registerForm.controls.recaptcha.setValue(data);
    }
  }
  handleReset():void {}
  handleExpire():void { }
  handleLoad():void { }

  ngOnDestroy():void {
    // remove the class form body tag
    this.documentService.body.classList.remove('blueBg');
  }

  createRegistrationForm():FormGroup {
    return this.formBuilder.group({
      firstName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      lastName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(50)],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      email: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.email],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      password: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(15), Validators.pattern(_REGEX_FOR_PASSWORD)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      confirmPassword: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(100)],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      country: this.formBuilder.control(null, {
        validators: [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      institutionLab: this.formBuilder.control(null, {
        validators: [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      dni: this.formBuilder.control(null, {
        validators: null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      telephone: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.maxLength(15)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      
      receiveUpdates: this.formBuilder.control(false, {
        validators: null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      contactPermission: this.formBuilder.control(false, {
        validators: null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      comments: this.formBuilder.control(null, {
        validators: [Validators.maxLength(500)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      recaptcha: this.formBuilder.control('', Validators.required),
      city: this.formBuilder.control(null, {
        validators: [Validators.maxLength(500)],
        asyncValidators: null,
        updateOn: 'blur'
      }),

    }, { validators: [repeatPasswordValidation] });
  }

  registerUser():void {
    const matSnackbarConfig = new MatSnackBarConfig();
    matSnackbarConfig.verticalPosition = 'top';
    matSnackbarConfig.horizontalPosition = 'end';

    if (this.registerForm.status === 'INVALID') {
      this.utils.markInvalidFields(this.registerForm);
    } else {
      const captchValue:string = this.registerForm.controls.recaptcha.value;     

      if (captchValue && captchValue.length > 0) {
        this.registrationProgress = 'in_progress';
        const form = this.registerForm.getRawValue();
        let { email, country, institutionLab, password, firstName, lastName, recaptcha, dni, telephone, comments, receiveUpdates, contactPermission, city } = form;
       
        if (receiveUpdates) {
          receiveUpdates = 'Y';
        } else {
          receiveUpdates = 'N';
        }

        if (contactPermission) {
          contactPermission = 'Y';
        } else {
          contactPermission = 'N';
        }

        const requestPayload = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          tokenId: email,
          countryCode: country,
          institution: institutionLab ? institutionLab : null,
          dniNumber: dni,
          password: password,
          shift: 'First Shift',
          langKey: 'en',
          login: email,
          authorities: [
            'ROLE_USER'
          ],
          mobileNumber: telephone,
          comments: comments,
          receiveUpdates: receiveUpdates,
          contactPermission: contactPermission,
          recaptcha: recaptcha,
          city:city
        };


        this.userService.registerUser(requestPayload)
          .subscribe(_ => {
            this.registrationProgress = 'completed';
            sessionStorage.setItem('showRegistrationMessage', 'Y');
            this.router.navigate(['/login'], { queryParamsHandling: "merge" });
          }, (error: HttpErrorResponse) => {
            this.registrationProgress = 'error';
            this.recaptcha2.reloadCaptcha();
            this.registerForm.controls.recaptcha.setValue(null);

            if (error.status === 400 && error.error.errorKey === 'userexists') {

              this.httpRegistrationError = {
                status: 400,
                errorKey: 'userexists',
                message: this.translateService.instant('userexists')
              };

            } else if (error.status === 400 && error.error.errorKey === 'EMAIL_IN_USE') {

              this.httpRegistrationError = {
                status: 400,
                errorKey: 'EMAIL_IN_USE',
                message: this.translateService.instant('email_in_use')
              };
            } else if (error.status === 400 && error.error.errorKey === "UNKNOWN_ERROR") {
              this.httpRegistrationError = {
                status: 400,
                errorKey: '',
                message: this.translateService.instant('unknown_error')
              };

            } else if (error.status === 400 && error.error.errorKey === 'CAPTCHA_ERROR') {
              this.recaptcha2.reloadCaptcha();
              this.registerForm.controls.recaptcha.setValue(null);

              this.httpRegistrationError = {
                status: 400,
                errorKey: '',
                message: error.error.message
              };
            } else {

              this.httpRegistrationError = {
                status: 400,
                errorKey: '',
                message: this.translateService.instant('registration_invalid_input') //'Please check you have entered valid information.'
              };
            }
          });
      } else {
        this.matSnackBar.open(this.translateService.instant('not_a_robot'));
      }

    }

  }

  navigateToLogin():void {
    this.router.navigate(['/login'], { queryParamsHandling: "merge" });
  }

  highLigth(controlName):string[] {
    return (this.registerForm.get(controlName).touched && this.registerForm.get(controlName).invalid) ? ['requiredFieldText'] : [''];
  }

  validateName():ValidationError {
    const firstName = this.registerForm.get('firstName') as FormControl;
    const lastName = this.registerForm.get('lastName')as FormControl;

    if (firstName.invalid) {

      if ((firstName.touched || firstName.dirty) && firstName.hasError('required')) {
        return { error: 'required', message: this.translateService.instant('firstName_required') };
      }

      if (firstName.hasError('pattern')) {
        return { error: 'pattern', message: this.translateService.instant('firstName_only_character') };
      }

      if (firstName.hasError('maxlength')) {
        return { error: 'pattern', message: this.translateService.instant('maxLength_exceed') };
      }

    }

    if (lastName.invalid) {
      if ((lastName.touched || lastName.dirty) && lastName.hasError('required')) {
        return { error: 'required', message: this.translateService.instant('lastName_required') }
      }

      if (lastName.hasError('pattern')) {
        return { error: 'pattern', message: this.translateService.instant('lastName_required_character') }
      }

      if (lastName.hasError('maxlength')) {
        return { error: 'pattern', message: this.translateService.instant('maxLength_exceed') };
      }
    }
  }

  togglePasswordMode():void{
    this.hide = !this.hide;
    const passwordField = this.passwordInputRef.nativeElement;
    passwordField.focus();
  } 

}

interface ValidationError{
  error:string; 
  message:string;
}
