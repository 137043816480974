import { Component} from '@angular/core';
import { MatDialogRef,  } from '@angular/material';
@Component({
  selector: 'app-logout-confirmation-dialog',
  templateUrl: './auth-alert.component.html',
  styleUrls: ['./auth-alert.component.scss']
})
export class AuthAlertDialogComponent {
  constructor( private dialogRef: MatDialogRef<AuthAlertDialogComponent>,
      /* @Inject(MAT_DIALOG_DATA) data */) {
  }

  confirm():void {
      this.dialogRef.close(true);
  }

  close():void {
      this.dialogRef.close(true);
  }
}
