import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-session-alert-dialog',
  templateUrl: './session-warning-alert.component.html',
  styleUrls: ['./session-warning-alert.component.scss']
})
export class SessionWarningAlertComponent implements AfterViewInit {

  secondsleft: number = 300; //5 minutes
  timeInterval = null
  constructor(
    private dialogRef: MatDialogRef<SessionWarningAlertComponent>,
     /*  @Inject(MAT_DIALOG_DATA) data */) {
  }

  ngAfterViewInit(): void {
    this.timeInterval = this.setTimeInterval();
  }

  setTimeInterval() {
    return setInterval(() => {
      this.secondsleft = this.secondsleft - 1;
      if (this.secondsleft <= 0) { this.dialogRef.close(false); }
    }, 1000)
  }

  keepLogin(): void {
    this.dialogRef.close(true);
  }

  logout(): void {
    this.dialogRef.close(false);
    // localStorage.clear();
    // sessionStorage.clear();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  }
}
