import { Component, OnInit } from '@angular/core';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { ApiService } from 'src/app/services/api-service.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/service/utils.service';
import { _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import repeatPasswordValidation from 'src/app/authentication/custom-validations';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpError } from 'src/app/types/interfaces';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordHttpReqState: HttpRequestState = 'not_initiated';
  passwordForm: FormGroup;
  httpPutError: Partial<CustomHttpError>
  hideCurrentPass = true;
  hideNewPass = true;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private translateService : TranslateService,
    private utils: Utils) { }

  cancel():void {
    this.router.navigate(['/user/lms-courses']);
  }

  initializeForm():void{
    this.passwordForm = this.formBuilder.group({
      currentPassword: [null, [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)]],
      password: [null, [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)]]
      }, { validators: repeatPasswordValidation });
  }

  ngOnInit():void {
    this.initializeForm()
  }

  highLigth(controlName:string):string[] {
    return this.passwordForm.get(controlName).touched && this.passwordForm.get(controlName).invalid ? ['requiredFieldText'] : [''];
  }


  saveUserDetails() {
    this.httpPutError = null;
    if (this.passwordForm.invalid) {
      this.utils.markInvalidFields(this.passwordForm);
    } else {
      this.changePasswordHttpReqState = 'in_progress';

      const updatedProfile = this.passwordForm.getRawValue();
      const requestBody = {
        currentPassword: updatedProfile.currentPassword,
        newPassword: updatedProfile.confirmPassword
      };

      this.apiService.postMethod('account/changePassword', requestBody)
        .subscribe((_) => {
          this.changePasswordHttpReqState = 'completed';
          this.initializeForm();
          this.userService.removeSessionDetails();
          this.router.navigate(['/login']);
        }, error => {
          this.changePasswordHttpReqState = 'error';
          this.initializeForm()

          if (error.error.statusCode === 400 && error.error.errorKey === 'PASSWORD_EXIST') {
            this.httpPutError = {
              status: 400,
              message: this.translateService.instant('changePassword_no_lastLeast_password') // 'You can not use last three password as your new password.',
            };

          }
          else if(error.error.statusCode === 400 && error.error.errorKey === 'INCORRECT_PASSWORD'){
            this.httpPutError = {
              status: 400,
              message: this.translateService.instant('incorrect_current_password') // 'The password you entered is incorrect',
            };
          }
          else {

            this.httpPutError = {
              status: error.status,
              message: this.translateService.instant('unable_to_change_password')  //'Error occured. Unable to change password.'
            };
          }
        });
    }

  }


}

 
